<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
//     if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
//     this.$router.replace("/appHome")
// } else {
//     this.$router.replace("/")
// }
  },
  
}
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?e30689069363f340203549302526d43d";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
  console.log(1234)
})();
</script>
<style lang="scss">
#app {
position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 12px;
}
a{
  color: white !important;
  border-style: none !important;
  text-decoration: none;
}

</style>
