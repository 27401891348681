import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //pc端
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/home.vue'),

  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: () => import('../views/productCenter/productCenter.vue'),
    redirect:"productCenter_intelligence",
    children:[
        {
          path: '/productCenter_intelligence',
          name: 'intelligence',
          component: () => import('../components/nProductCenter/intelligence.vue'),

        },
        {
          path: '/productCenter/cloudRestaurant',
          name: 'cloudRestaurant',
          component: () => import('../components/nProductCenter/cloudRestaurant.vue'),

        },
        {
          path: '/productCenter/aiSystem',
          name: 'aiSystem',
          component: () => import('../components/nProductCenter/aiSystem.vue'),

        },
        {
          path: '/productCenter/wisdomCerebrum',
          name: 'wisdomCerebrum',
          component: () => import('../components/nProductCenter/wisdomCerebrum.vue'),

        },
    ]
  }, {
    path: '/afterSale',
    name: 'afterSale',
    component: () => import('../views/afterSale/afterSale.vue')
  }, {
    path: '/business',
    name: 'business',
    component: () => import('../views/business/business.vue')
  }, {
    path: '/practical',
    name: 'practical',
    component: () => import('../views/practical/practical.vue')
  },{
    path: '/newsPage',
    name: 'newsPage',
    component: () => import('../views/home/newsPage.vue')
  },
  {
    path: '/personnel',
    name: 'personnel',
    component: () => import('../views/personnel/personnel.vue')
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('../views/newsCenter/newsCenter.vue')
  },
  {
    path:"/coreProducts",
    name:"coreProducts",
    component: () => import('../views/home/home.vue'),

  },
  {
    path:"/programme",
    name:"programme",
    component: () => import('../views/home/home.vue'),

  },
  {
    path:"/advantage",
    name:"advantage",
    component: () => import('../views/home/home.vue'),

  },
  {
    path:"/choiceMe",
    name:"choiceMe",
    component: () => import('../views/home/home.vue'),

  },

  {
    path:"/digitalManage",
    name:"digitalManage",
    component: () => import('../views/home/home.vue'),

  },
  {
    path:"/partner",
    name:"partner",
    component: () => import('../views/home/home.vue'),

  },
  {
    path: '/dynamic',
    name: 'dynamic',
    component: () => import('../views/newsCenter/newsCenter.vue')
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/newsCenter/newsCenter.vue')
  },
  {
    path: '/companyProfile',
    name: 'companyProfile',
    component: () => import('../views/business/business.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/business/business.vue')
  },
  {
    path: '/gtMap',
    name: 'gtMap',
    component: () => import('../views/business/business.vue')
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: () => import('../views/personnel/personnel.vue')
  },
  {
    path: '/concat',
    name: 'concat',
    component: () => import('../views/personnel/personnel.vue')
  },
//移动端
{
  path: '/appHome',
  name: 'appHome',
  component: () => import('../views/app/appHome.vue'),

},


]

const router = new VueRouter({
  routes
})
const originalReplace = VueRouter.prototype.replace;
   VueRouter.prototype.replace = function replace(location) {
   return originalReplace.call(this, location).catch(err => err);
};
export default router
