import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newsData:{ //用于到新闻详情页
      dynamic:[
        {
          imgPath:require("../assets/imgs/home/news/company1.jpg"),
          newsTitle:"健康餐饮-AI食安溯源",
          date:"2020-09-24",
          newsContent:'“民以食为天,食以安为先”食品安全是目前国内外研究和关注的焦点,食品安全不仅关系到人民的身体健康,生命安全,还关系到国民的经济发展和社会的稳定。<br/>' +
              '<br/>' +
              '     在经济利益的驱动下,目前我国食品安全问题严重,存在着极大的安全隐患。对当前食品安全的问题,基于大数据和人工智能技术构建出食品安全溯源，通过深度学习等人工智能技术,对复杂场景下的高维数据进行深入挖掘分析,化解群体性的食品安全事故。通过合理分工,解决了数据收集、上报、分析到预警的全环节技术难题,提高溯源数据的全覆盖性、实时性、预警准确性和智能化程度。<br/>' +
              '<br/>' +
              '    “AI食安溯源系统”采用人工智能识别技术，对采购食材进行智能识别及食材评价和入库溯源，这套系统全方面诠释了食品安全的溯源追溯体系。'
        },
        {
          imgPath:require("../assets/imgs/home/news/company2.png"),
          newsTitle:"综合智慧餐饮管理系统—非接触式云食堂",
          date:"2020-05-08",
          newsContent:'综合智慧餐饮管理系统适用于高校、企事业单位应用，是一个迎合移动互联时代潮流，基于现有食堂管理模式，创新打造便捷的食堂订餐及就餐模式，可实现精准备餐/采购、AI智能优化生产、加强就餐者和食堂的互动、 提升就餐体验和食堂服务水平，同时鼓励厨师创新，推陈出新，通过对月度及年度数据的分析比对、科学考核；并对食材质量追根溯源；为食堂提供采购、库存的自动化、数字化的管理，具有安全可靠，维护简单方便的特点。<br/>' +
              '<br/>' +
              '当前“新冠肺炎”疫情给餐饮业按下暂停键；市场呼之而出的四大新诉求：零接触配餐、“分餐公勺”制、集中式操作以及安全健康；浙江国通电气科技有限公司在综合智慧餐饮管理系统上针对疫情，推出“非接触式智慧云食堂”点餐系统，帮助餐饮企业解决当前问题：① 疫情期间，“非接触式智慧云食堂”推出的点餐系统，为广大职工提供多种菜品选择；<br/>' +
              '<br/>' +
              '② 拥有统一工号的职工可根据系统提示进行注册，注册后进入系统进行中餐预订；<br/>' +
              '<br/>' +
              '③领餐时仍以部门为单位领取，各部门派代表时段到食堂领餐，领餐安排以错开时间段进行，并且领餐、取餐排队要隔一段距离。<br/>' +
              '<br/>' +
              '二、“分餐公勺”制<br/>' +
              '<br/>' +
              '    为了守住大家“舌尖上的健康”，众多地区的餐饮行业以及家庭用餐都在推广公筷公勺制、分餐制，践行餐桌文明。分餐制的优点：<br/>' +
              '<br/>' +
              '① 减少交叉感染：“夹菜”是中国独有的饭桌文化，因此，分餐能极大程度减少各种消化道疾病传播的概率，可预防各种经口、唾液传播；<br/>' +
              '<br/>' +
              '② 保证营养均衡：分餐制可以根据每人每餐需要的营养，搭配一定数量的饭菜；控制进食量，防止挑食偏食；<br/>' +
              '<br/>' +
              '③ 减少食材浪费：家庭实行分餐制，掌勺人按需购买、准备食材，在一定程度上能避免食物的浪费。<br/>' +
              '<br/>' +
              '三、AI智能优化生产<br/>' +
              '<br/>' +
              '    采购、库存实现数字量化，并与生产、售卖实现数字化连通，能有效减少损耗浪费，让食堂运转更高效，决策更科学。<br/>' +
              '<br/>' +
              '四、食材安全追根溯源<br/>' +
              '<br/>' +
              '    对食材测评，品控把关，实现食材验收的标准化、可视化，实时采集上传留存比对，通过大数据分析达到对供应商奖优罚劣目的'
        },
        {
          imgPath:require("../assets/imgs/home/news/company3.jpg"),
          newsTitle:"疫情期间，企业食堂全流程无接触配餐",
          date:"2020-03-05",
          newsContent:'  随着新型冠状病毒肺炎的演进，全国各地均采取了“冰冻社会”的隔离模式来应对快速传播的病毒，这使得无接触商业模式迅速爆发，生鲜电商、在线娱乐等业态迎来高光时刻。<br/>' +
              '<br/>' +
              '    自2月中旬起，企业的复工潮陆续来临，可预计的是，随着复工人数越来越多，疫情中的企业防护服务必将成为新的增长极。<br/>' +
              '<br/>' +
              '在这之中，员工该如何就餐想必是最困扰企业主的问题之一了。之前互联网大厂的食堂福利一直被网友所称道，如今复工后其食堂的无接触新举措同样值得关注<br/>' +
              '<br/>' +
              '特殊时期的非接触式云餐厅<br/>' +
              '<br/>' +
              '    疫情来临之际，作为典型人员聚集场所的团餐，也成为了公共卫生安全防护的重难点区域。怎么在保证安全的前提下，保障员工的饮食不受影响，这已经成为了复工企业的首要问题。<br/>' +
              '<br/>' +
              '    餐饮知名人士介绍：“当前公共卫生安全新态势下，企业食堂有四大新诉求：零接触配餐、非聚集就餐、集中式操作以及安全健康，“非接触式云餐厅”点餐系统正是致力于帮助企业解决这些问题。”<br/>' +
              '<br/>' +
              '    对于企业员工而言，非接触式云餐厅的手机端小程序订餐方案较易操作。首先，食堂会按周发布每餐的供应品种和数量；然后，员工可以通过手机端预约点餐，用企业给每个员工的虚拟点数来支付；紧接着，后厨按单生产，提高效率；随后食堂按照部门打包，由部门代表到食堂领取。'
        },
        {
          imgPath:require("../assets/imgs/home/news/company4.jpg"),
          newsTitle:"运用物联网AI智能，明确责任，对食物质量追根溯源",
          date:"2020-04-16",
          newsContent:'产销数字化，决策更科学<br/>' +
              '<br/>' +
              '智能食材检测系统是采购、库存实现数字量化，并与生产、售卖实现数字化连通，能有效减少损耗浪费，让食堂运转更高效，决策更科学。<br/>' +
              '<br/>' +
              '1、建立健全的监管制度，杜绝小微权力，做到公开透明；<br/>' +
              '<br/>' +
              '2、展现理论耗用和实际耗用的比对分析，核算菜品成本；<br/>' +
              '<br/>' +
              '3、供应商的评价管理体系，对供应商每次供应进行星级评分，有利优化供应体系；<br/>' +
              '<br/>' +
              '4、称重收货，拍照自动入库。' +
              '安全溯源<br/>' +
              '<br/>' +
              '食品安全是事关人体健康的公共卫生问题，降低疾病隐患，防范食物中毒是食品安全工作的重中之重，智能食材检测系统通过数字化的方式打通上下游数据链，能有效追踪食物来源及去向，食品安全更有保障。'
        }
      ],
      realInfo:[
        {
          imgPath:require("../assets/imgs/home/news/ind1.jpg"),
          newsTitle:"第二十届上海国际食品加工与包装机械展",
          date:"2020-12-10",
          newsContent:'日前在国家会展中心（上海）闭幕的第二十届上海国际食品加工与包装机械展上，浙江国通电气科技有限公司展出“数字大脑智慧健康餐饮管理系统”和“AI食安溯源系统”，以其在“降本增效”、“保障食品安全”、“杜绝小微权利”等方面独到的产品构思，以及融汇AI人工智能、大数据分析等技术的优异系统性能，获得与会客户的一致好评，并已与多家企业达成合作意向。'
        },
        {
          imgPath:require("../assets/imgs/home/news/ind2.jpg"),
          newsTitle:"政府助力 企业腾飞",
          date:"2020-06-30",
          newsContent:'杭州市未来科技城梦想小镇作为当下浙江创新创业、促进经济数字化转型的新高地，每年举办的“创业先锋营”大赛都吸引省内外几百家优秀企业前来角逐最后的“金钥匙”奖。<br/>' +
              '<br/>' +
              '浙江国通电气科技有限公司研发的“数字大脑智慧健康餐饮管理系统”，经专家组评审，在闯过初审后的几十家优秀企业项目中脱颖而出，斩获金钥匙奖，享受政府扶植优惠补贴政策入驻梦想小镇，为企业下一步的高速发展提供有效助力。'
        },
        {
          imgPath:require("../assets/imgs/home/news/ind3.png"),
          newsTitle:"党史小故事 | 关于一封节约粮食的联名！",
          date:"2021-04-09",
          newsContent:' “1934年3月20日，中央苏区机关报《红色中华》刊登了一封联名信。当时一批从“白区”来苏区工作的干部，就《红色中华》提出节省运动的号召，向编辑部去信告知他们具体响应号召的办法。信是这么说的：我们是从白区来的，我们在苏区没有分田，但是我们为着革命战争，使我们能在持久战中取得彻底胜利，愿意：一，每天节省二两米，使前方红军吃饱，好打胜仗；二，今年公家不发我们热天衣服，把这些衣服给新战士穿。我们要求其他白区来的同志，和在苏区分了田的同志，都同我们一起，来响应《红色中华》的节省号召！<br/>' +
              '<br/>' +
              '    由于这些“白区”来的同志，在苏区没有土地等生产资料，其生活必需品主要靠单位供给。这封信的署名人一共有二十三位，其中有不少我们熟悉的名字：陆定一、邓颖超、博古、毛泽覃、成仿吾等。当时的中央苏区干部，不仅自发节约粮食，甚至在有的情况下，自带粮食上班开会，不要公家发放的粮食。笔者寻到1933年11月26日出版的《红色中华》中刊登了一则短消息：“杨殷县泮溪区长教乡，日前各当选的代表集中开会时，一致要求自带伙食，而把公家发给的伙食费，捐助红军战费”，随后简要介绍了泮溪区苏维埃新当选代表的提案，基本都与农业生产有关。”摘自“党史小故事丨一封节约粮食的联名”。'
        },
        {
          imgPath:require("../assets/imgs/home/news/ind4.png"),
          newsTitle:"数字大脑智慧健康餐饮是如何做到， 传统式食堂唾弃粗放式管理模式？",
          date:"2020-12-22",
          newsContent:' 随着人工智能、大数据的发展，越来越多的行业加入了信息化的行列。尤其是与人们生活息息相关的衣食住行。最近因新冠疫情热度持续走高，在信息化和传统食堂弊端不断的双重影响下，“数字大脑智慧健康餐饮”也随着大环境趋势情况下应运而生的。  与其说是“数字大脑智慧健康餐饮”取代了传统食堂，倒不如说，“数字大脑智慧健康餐饮管理系统”是传统食堂在信息化时代的转型。传统食堂食材浪费，无法闭环管理，就餐体验差等问题都随着“数字大脑智慧健康餐饮”的出现而得到解决。<br/>' +
              '<br/>' +
              '传统式食堂有哪些粗放式管理模式呢？<br/>' +
              '<br/>' +
              '1.   传统食堂厨师专业水平有限、服务质量低下：受厨师规模和水平的限制，无法进行厨艺交流，且又不能更换厨师影响稳定，菜品种类更新比较慢，水平提升慢，饭菜质量难保证，服务质量难提高。<br/>' +
              '<br/>' +
              '2.   传统食堂采购成本高、管理效率低:劳动生产率管理低下，在食品原材料的采购中成本过高，在食品加工过程中浪费过多，致使食堂效率效益差，经济负担日益加重。<br/>' +
              '<br/>' +
              '3.   传统食堂缺乏有效监管、安全风险较高:团餐是一个非常微利的行业，稍微不注意就会出现管理不善，监管人员仅仅是注重注重卫生状况和基本安全防范，深层次的质量监督和质量检查是无法做到，容易造成更大的潜在风险。<br/>' +
              '<br/>' +
              '4.   传统食堂排队时间长、结账易出错:用餐时间集中，人流量大会造成食堂排队时间长，依赖人工收银，靠收银人员计价，速度慢、易出错，容易造成排长队、引发纠纷。    “数字大脑智慧健康餐饮”是由数字大脑智慧健康餐饮管理后台系统、数字大脑智慧健康餐饮管理点餐系统、AI食材溯源系统、数字大脑智慧健康餐饮管理后勤管理系统组成，每个系统除了优化传统食堂经营过程中的弊端外，还提供了整体的数据支持而食堂管理者可通过数字大脑智慧健康餐饮管理系统各个环节的详尽数据更好的管理食堂。'
        }
      ],
      mediaCan:[
        {
          imgPath:require("../assets/imgs/home/news/med1.jpg"),
          newsTitle:"省委书记袁家军接受中新社专访：为全球数字变革写下“浙江方案”",
          date:"2021-03-23",
          newsContent:'全国人大代表、浙江省委书记袁家军。<br/>' +
              '<br/>' +
              '　“这些年，我们一直围绕数字浙江建设持续发力，坚持以人民为中心的发展思想，深化‘最多跑一次’改革，大力推动政府数字化转型，并撬动经济社会全方位数字化转型，在省域层面先行探索‘万物互联’时代的政府治理、经济治理、社会治理，省域治理体系和治理能力现代化程度显著提升。”全国人大代表、浙江省委书记袁家军说。<br/>' +
              '<br/>' +
              '    “万物互联”时代，如何在全球数字变革的“风口”占据先机？全国两会期间，袁家军在接受中国新闻社专访时，就浙江推进数字化改革的主要目标举措作介绍，解开何为全球数字变革下的“浙江方案”。<br/>' +
              '<br/>' +
              '方案之源：“数字浙江”的一脉相承<br/>' +
              '<br/>' +
              '    春节假期后首个工作日，浙江召开数字化改革大会，希望将其打造为“重要窗口”的重大标志性成果。<br/>' +
              '<br/>' +
              '    合抱之木，生于毫末；九层之台，起于累土。<br/>' +
              '<br/>' +
              '    袁家军表示，这些年，浙江坚定不移落实习近平总书记在浙江工作期间作出的建设数字浙江重大决策部署，为推进数字化改革打下坚实基础。他提到，特别是2017年以来，数字浙江建设加速推进，大致可以分为“最多跑一次”改革、政府数字化转型和数字化改革三个阶段。　其中，2017年全面启动的“最多跑一次”改革是该省一项“牵一发动全身”的重大改革。在袁家军看来，其形成了政府数字化转型的先发优势。而这一阶段最主要的标志性成果是政务服务“一张网”“一窗受理”“一证通办”。“从‘最多跑一次’改革到政府数字化转型是改革的一次迭代深化。”袁家军说，其最显著的特征便是数字赋能。<br/>' +
              '<br/>' +
              '     随着政府数字化不断推进，目前“浙里办”注册用户已达5515万，网上可办率100%，跑零次可办率达97.4%；“浙政钉”政务协同总平台集成1278个应用，实现部门间高效协同。<br/>' +
              '<br/>' +
              '     袁家军称，2020年政府数字化转型聚焦系统融合、综合集成，实现了从点到面、从部门分割到整体协同的螺旋式上升。<br/>' +
              '<br/>' +
              '   “新冠肺炎疫情防控是最重要的成果展示场景，我们充分发挥政府数字化转型体系化优势，以‘大数据分析+网格化管理’，建立‘一库一图一码一指数’的精密智控机制。”袁家军说。<br/>' +
              '<br/>' +
              '  方案之旨：于全方位升级中推动“质变”<br/>' +
              '<br/>' +
              '　 谋定而后动，知进而有得。眼下，该省进入数字浙江的第三个阶段——数字化改革。<br/>' +
              '<br/>' +
              '  “数字化改革是政府数字化转型的一次全方位拓展和升级，是浙江立足新发展阶段、贯彻新发展理念、构建新发展格局的重大战略举措。”袁家军阐述，其主要体现在三个方面：<br/>' +
              '<br/>' +
              '　 从内涵来看，数字化改革是技术理性向制度理性的新跨越；从领域来看，其撬动全方位、全过程、全领域的数字化改革跨越；从价值来看，其树立数字意识和思维、培养数字能力和方法、构建数字治理体系和机制等，主动引领全球数字变革的跨越。<br/>' +
              '<br/>' +
              '“数字化改革的意义不仅仅在具体的场景应用上，更在于推动生产方式、生活方式、治理方式发生基础性、全局性和根本性的改变，要实现的是质变而不仅仅是量变。”袁家军说。<br/>' +
              '<br/>' +
              '　对于以“争创社会主义现代化先行省”为目标任务的浙江来说，推进省域治理体系和治理能力现代化，亦是数字化改革的重要目的。<br/>' +
              '<br/>' +
              '    为实现上述目标，袁家军提出要推进深层次系统性制度重塑，全面优化营商环境，加快完善高质量发展、高水平均衡、高品质生活、高效能治理的体制机制等。<br/>' +
              '<br/>' +
              '    于市场而言，数字化改革亦是激发活力、增添动力的“关键因子”。他说，应破解要素流动不畅、资源配置效率不高等制约高质量发展的瓶颈，为社会、市场、经济增添新动能、创造新价值。<br/>' +
              '<br/>' +
              '  方案之法：复杂系统工程下的久久为功<br/>' +
              '<br/>' +
              '     知其源，识其旨，还需懂其法。袁家军说，数字化改革是一项复杂的系统工程，也是一个长期的螺旋式迭代过程。<br/>' +
              '<br/>' +
              '  　他向中国新闻社记者展开这张时间表、路线图：到2021年底，初步建成一体化智能化公共数据平台，五大综合应用实现基本模块全上线、省市县全贯通；到2022年底，全面建成“掌上办事之省”“掌上办公之省”“掌上治理之省”；到2025年底，基本建成“整体智治、唯实惟先”的现代政府和建成全球数字变革高地。<br/>' +
              '<br/>' +
              '　袁家军还具体阐述这项复杂系统的“四梁八柱”，即加快构建“152”工作体系。<br/>' +
              '<br/>' +
              '    其中，“1”即一体化智能化公共数据平台，通过形成省市县三级“平台+大脑”的体制，打造智慧化平台中枢，支撑各级各系统应用创新；“5”即党政机关整体智治综合应用、数字政府综合应用等五个综合应用。<br/>' +
              '<br/>' +
              '   “这五个综合应用相互关联、相互作用，共同构成了数字化改革的整体，从而把数字化、一体化、现代化贯穿到党的领导和经济、政治、文化、社会、生态文明建设全过程各方面。”袁家军说。<br/>' +
              '<br/>' +
              '    “2”则为构建理论体系和制度规范体系两套体系，推动改革实践上升为理论成果、固化为制度成果。<br/>' +
              '<br/>' +
              '　　以这份“浙江方案”为蓝图，随着数字壁垒的打破，数字鸿沟的消除，在袁家军看来，在打造全球数字变革高地的道路上，浙江正向着实现全社会共享“数字红利”的美好愿景大步迈进。'
        },
        {
          imgPath:require("../assets/imgs/home/news/med2.jpg"),
          newsTitle:"全国人大代表、苏宁控股集团董事长张近东：建议推动农村零售业态升级",
          date:"2021-03-04",
          newsContent:' 2021全国两会召开在即，3月3日，记者从苏宁获悉，全国人大代表、苏宁控股集团董事长张近东今年的建议将重点关注乡村振兴、循环经济、数据共享、绿色物流、小微企业发展等问题。<br/>' +
              '<br/>' +
              '建言技术赋能，推动农村零售业态升级<br/>' +
              '<br/>' +
              '    当前，我国脱贫攻坚战已经取得了全面胜利，乡村振兴正在成为社会各界关注的重点。 张近东在调研中发现，由于经营模式传统，零售供给体系滞后，农村消费市场长期存在假冒伪劣品横行、质次价高、服务质量参差不齐等现象。农村市场优质商品及服务有效供给不足，农村居民对美好生活追求的需求无法得到满足，已经成为制约农村零售市场发展的主要问题。<br/>' +
              '<br/>' +
              '    对此，张近东建议，将品质升级、体验升级作为乡村商业消费升级的主要方向，通过对农村小微零售企业的数字化赋能，引领带动农村消费品质升级，充分释放农村消费潜力。同时，也要把农村电商人才培训作为系统工程，为再造线上线下融合发展的农村实体零售夯实人才基础。<br/>' +
              '<br/>' +
              '关注数字经济：逐步推进公共数据的无偿共享<br/>' +
              '<br/>' +
              '    过去一年，全国各地都在积极防控新冠肺炎疫情，在保障社会正常运转的过程中，防疫数据快速及时的互通、流转与共享起到了关键作用。这也让社会各界进一步认识到，公共数据的无偿共享，是提升社会效率，有利人们更好生活的利民工程。<br/>' +
              '<br/>' +
              '    张近东认为，推动公共数据的互联、无偿共享，具有多重利好。不仅有利于公共事务的决策效率，还能避免数据的重复建设，避免因数据“打架”导致的信息混乱。同时，这一工程还将使公共数据被最大化的利用，对提升政府治理能力、优化民生公共服务、促进经济高效发展有重大意义。<br/>' +
              '<br/>' +
              '    他建议，建立国家级数据共享平台，实现全国数据的顶层管控，界定清晰的数据共享属性和权益，实现数据的确权流通，同时要加强数据资源无偿共享，但要保障数据共享的安全可控。<br/>' +
              '<br/>' +
              '发展循环经济：打造家电以旧换新行业闭环<br/>' +
              '<br/>' +
              '    数据显示，国内家电市场2020年一季度零售下降近4成，全年零售额约7083亿元，同比下降11.1%。2020年，全国居民每百户移动电话拥有量增长0.2%，空调拥有量增长1.8%，排油烟机拥有量增长2.6%。这几类商品的拥有量增幅，分别低于2019年的1.7%、5.8%、5.2%。 与之相对应的是，上一轮家电下乡和以旧换新开展十余年后，我国家电保有量已超过21亿台。一方面是大量传统家电步入报废或超期服务关口，带来安全和健康隐患，另一方面是家电行业急需发展新动能。<br/>' +
              '<br/>' +
              '    张近东建议加强家电安全使用年限普及，积极引导以旧换新。建立家电回收机制，产业上下游组建高标准闭环。与此同时，要加大对消费者补贴力度，推动线上线下融合的收旧换新模式。<br/>' +
              '<br/>' +
              '聚焦绿色物流：建设包材公共回收处理机制<br/>' +
              '<br/>' +
              '    绿色物流不仅是经济与社会发展的客观要求，也是物流发展的必然选择。它不但可以实现物流整体系统最优化，还能使对环境的破坏降到最低，对环境保护和经济的可持续发展具有重要的意义。但张近东在调研中发现，单独建立的绿色物流循环体系无法实现行业间循环，难以形成规模效益实现降本增效，与环保相关的回收及处理过程相对专业且投入较大，企业独立建设较为困难。<br/>' +
              '<br/>' +
              '    张近东建议，启动绿色环保的立法工作，通过法律法规强制推行绿色物流体系建设，同时参照城市垃圾分类回收体系推动包装垃圾回收与处理体系建设，以加快绿色物流建设步伐。<br/>' +
              '<br/>' +
              '    此外，针对商贸流通领域存在的物流费用占GDP的比例偏高的问题，张近东建议通过提升线上线下融合、加强流通一体化设计以及加快软硬件改造等措施来提高流通质量效率。<br/>' +
              '<br/>' +
              '助力小微企业：进一步推动地方数字化集成发展<br/>' +
              '<br/>' +
              '    近年来，以多维度数据集成、共享为特征的中小企业信用体系建设取得明显成效，但“融资难、融资贵”始终是阻碍小微企业和农村金融等领域进一步发展的重要问题。而在新冠肺炎疫情加速社会数字化转型的背景下，如何利用数字化手段解决小微企业融资问题值得重点关注。<br/>' +
              '<br/>' +
              '    张近东认为，地方政府的小微企业数字化平台建设，有助于缓解这一困境，且这类平台有望成为普惠金融的“新基建”。他建议由政府牵头探索建设和完善区域小微企业数据集成系统，打造开放式的小微企业数字化平台，加固完善政府管理服务、小微企业信息提取和金融机构付费合作的市场化联系。同时在小微和民营企业数据建设基础更好的地区率先成立小微企业数字平台展开试点。'
        },
        {
          imgPath:require("../assets/imgs/home/news/med3.jpg"),
          newsTitle:"市场监管总局：春节期间强化进口冷链食品追溯管理",
          date:"2021-01-14",
          newsContent:'会议指出，各级市场监管部门要从讲政治的高度保障好春节和“两会”期间的食品安全，突出重点、强化监管，及时排查风险隐患，切实保障人民群众食品安全，为新的一年开好局、起好步。<br/>' +
              '<br/>' +
              '    会议强调，要针对节日期间食品消费特点，加强重点食品监管，规范网络食品经营，加大餐饮服务单位食品安全和疫情防控检查力度，特别是对农村地区集体聚餐、集会等重点区域开展风险排查防控，加大监督抽检力度，消除安全隐患。依法严惩保健食品虚假宣传等违法违规行为，维护好消费者合法权益。坚决制止餐饮浪费，禁止滥食野生动物。强化进口冷链食品追溯管理，加快平台推广应用，助力疫情防控。<br/>' +
              '<br/>' +
              '    会议要求，各级市场监管部门要落实属地管理责任，强化组织领导，加强应急值守，统筹安排，周密部署，加强监督，努力营造良好节日氛围。<br/>' +
              '<br/>' +
              '    总局食品安全总监，总局相关司局和单位负责同志在主会场参加会议。各省、自治区、直辖市和新疆生产建设兵团市场监管局（厅、委）分管负责同志在分会场参加会议。'
        },
        {
          imgPath:require("../assets/imgs/home/news/med4.png"),
          newsTitle:"数字化助力农产品高质量发展",
          date:"2020-12-16",
          newsContent:'洪涛首先介绍了数字农产品电商相关内容。自1946年计算机诞生以来，各种信息技术浪潮向我们迎面扑来，包括计算机、互联网、物联网、大数据、云计算、区块链、人工智能等等浪潮。现在又迎来了一个新的浪潮，即各种信息技术综合运用的“数字化浪潮”。数字技术快速渗透到各行各业，我国农产品电商也已进入“数字化发展”新阶段。在农产品方面，“供给大于需求”同时“供给又小于需求（特色、品质产品）”。如何使农产品种得好、养得好、加工得好、还要卖得好，消费者得实惠、农民得收益，需要深刻认识到消费者的需求变化。我国人民群众经历了从“吃不饱”到“吃得饱”“吃得安全”“吃得健康”，即将进入一个新的阶段，就是“吃得幸福”。党的十九届五中全会对改善“人民生活品质”提出了更高要求，人们对优质、特色农产品消费需求还会持续增加，加快推动符合消费升级需求的优质农产品上行已成为迫切需要。市场在变化，消费在升级。农业供给侧结构性改革在逐渐的有序向前推进。要以消费升级为导向，加快推进供给侧结构性改革，进一步推动消费升级，以新消费引领新供给，以新供给创造新需求。<br/>' +
              '<br/>' +
              '    我国农产品曾处于供不应求、短缺的阶段，现在则是“供给大于需求”和“供给小于需求”同时并存的时期，许多优质、特色、品质的农产品仍然“供不应求”，数量的风险虽然依然存在，但是质量风险相对比较高，还具有价格风险、信用风险、金融风险，影响了我国的农产品销售，导致销量不够好，销售价格不够高。传统农产品流通领域的诚信缺失问题层出不穷，电商涉足农产品流通，诚信缺失问题仍未解决；从电子商务角度来讲，存在电商交易过程风险、食品安全电商风险、电商偷税漏税风险、电商模式同质化风险、网络刷屏和虚拟交易风险。随着数字农产品电商的出现，又产生数字故障以及视频直播电商过程中出现的一些风险。视频直播电商是2020年的一个新的热点和亮点，存在着电子商务交易风险，供过于求的风险以及同时存在着特色优质农产品供不应求的风险。<br/>' +
              '<br/>' +
              '    针对这些情况，洪涛表示需要通过数字化治理来解决现在的问题。<br/>' +
              '<br/>' +
              '    一是通过政府、行业协会、平台、企业和个人，适应这种数字化的变化，将生产数字化、流通数字化、消费数字化，通过各种各样的数字技术解决问题，也就是将产品数字化、交易数据和支付结算数字化、环境数字化、空间数字化、再生资源数字化、供应链的数字化。这些数字化归结起来就是“一切业务数字化，一切数字业务化，一切业务用数字来说话”，从而提高产品品质。<br/>' +
              '<br/>' +
              '    二是视频直播数字化，通过“直播带货”向“直播应货”转变，根据消费者和市场需求进行“视频直播购物”转型升级。<br/>' +
              '<br/>' +
              '    三是以平台为主的多层次的数字治理，包括政府的数字监管、行业协会的数字治理、平台的数字监管和企业个人的数字自律，其中最关键的就是抓住平台的数字化管理。从而管理部门也逐渐由传统的治理向网络治理、实效治理、数字治理飞跃。<br/>' +
              '<br/>' +
              '    四是通过数字食品和数字农产品来做到“五联”“五通”，“五联”是指联产品、联数据、联标准、联设施、联市场；“五通”就是通乡村振兴、通内外市场、通品牌质量、通源头追溯、通模式创新。<br/>' +
              '<br/>' +
              '    五是要制定食品数字治理的13个基本内容。生产（种、养、加）的数字化（3个），消费的数字化（1个），中间流通的数字化，包括8个环节的数字化，即商品数字化、交易数字化、物配数字化、支付结算数字化、环境数字化、卖场数字化、再生资源数字化、供应链数字化，此外与生产数字化、流通的数字化、消费的数字化相适应的是“社会治理数字化”。“社会治理数字化”转型，可以归纳为：“一切业务数字化、一切数字业务化、一切业务用数字来说话”。<br/>' +
              '<br/>' +
              '    六是采取多种数字技术综合应用。要解决现有的食品或者农产品安全的问题，必须综合运用多种信息技术。<br/>' +
              '<br/>' +
              '    七是以消费者和市场为导向。现在市场已经发生变化，要以市场和消费者需求为导向，种植、养殖、加工、销售相应农产品。<br/>' +
              '<br/>' +
              '    八是以“产品码”为核心的多种数字模式的应用。这里的“产品码”就像现在的人的“健康码”一样，今后产品也要有“健康码”。<br/>' +
              '<br/>' +
              '    最后，洪涛提出三点需要关注的地方：一是《中欧地理标志协定》将在2021年开始实施，要做好地理标志专用标志换标工作。据悉，机构改革前，地理标志保护产品由原国家质量监督管理总局、农业农村部管理，地理标志商标由原国家工商行政管理总局商标局管理。机构改革后，地理标志由国家知识产权局统一管理。国家知识产权局于2019年10月16日发布地理标志专用标志官方标志，原相关地理标志产品专用标志同时废止，原标志使用过渡期至2020年12月31日。二是今年11月15日区域全面经济伙伴关系协定（“RCEP”）协议的签订，同时，我国开展计划进入CPTPP，这对我国农产品进出口贸易会有重要的影响。三是近两年来各个电商巨头纷纷进入社区生鲜团购，产生了积极作用，同时也会产生同质化竞争，增加竞争的压力。'
        }
      ]
    },
    newsIndex:0,
    newsType:"dynamic"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
