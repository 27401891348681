import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'babel-polyfill'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animated from 'animate.css'
import wow from 'wowjs'
import 'amfe-flexible/index.js'
import 'amfe-flexible'
import 'wowjs/css/libs/animate.css'  //此处就是wowjs中自带的animate的位置
Vue.prototype.$wow = wow
Vue.use(animated)
Vue.use(ElementUI);
Vue.config.productionTip = false
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '9f4bbe323ea1b8ab6bcfab75da5d9acd',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.Geocoder','AMap.CitySearch'],
  v: '1.4.4'
})
// let screenWidth = document.documentElement.clientWidth;
// let psdWitdh = 1920;
// let prevFs = 100;
// let currentFS = screenWidth * prevFs / psdWitdh; //当前屏幕的字体基准值

// document.documentElement.style.fontSize = currentFS + 'px ' ;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
